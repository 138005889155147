// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { loginUser as login } from '../services/AuthService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Check for token on mount
  useEffect(() => {
    const checkUser = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const parsedUser = JSON.parse(storedUser);
          setUser(parsedUser);
        }
      } catch (error) {
        console.error('Error checking user:', error);
      } finally {
        setLoading(false);
      }
    };

    checkUser();
  }, []);

  // Login function
  const loginUser = async (email, password) => {
    try {
      setLoading(true);
      const data = await login(email, password);
      if (data.token) {
        // Include the token in the saved user data
        const userData = { ...data.user, token: data.token };
        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
        return { success: true };
      } else {
        return { success: false, message: 'Login failed: No token received' };
      }
    } catch (error) {
      console.error('Login error:', error);
      return { success: false, message: error.message };
    } finally {
      setLoading(false);
    }
  };

  // Logout function
  const logout = () => {
    localStorage.removeItem('user');
    setUser(null);
    // Navigate using window.location instead of useNavigate
    window.location.href = '/login';
  };

  // Update user profile data
  const updateUser = (updatedUserData) => {
    setUser(updatedUserData);
    localStorage.setItem('user', JSON.stringify(updatedUserData));
  };

  // Handle token expiration when API calls fail
  const handleTokenExpiration = (error) => {
    if (error.message?.includes('jwt expired') || error.status === 401) {
      logout();
      return true;
    }
    return false;
  };

  return (
    <AuthContext.Provider value={{ user, loading, loginUser, logout, updateUser, handleTokenExpiration }}>
      {children}
    </AuthContext.Provider>
  );
};
