// src/components/LoginForm.js
import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import AuthLayout from '../AuthLayout'; 

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [message, setMessage] = useState({ type: '', text: '' });
  const [loading, setLoading] = useState(false);
  
  const { loginUser } = useContext(AuthContext);
  const navigate = useNavigate();
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });
    
    try {
      const result = await loginUser(formData.email, formData.password);
      
      if (result.success) {
        // Redirect to home page on successful login
        navigate('/');
      } else {
        setMessage({ 
          type: 'error', 
          text: result.message || 'Login failed. Please check your credentials.' 
        });
      }
    } catch (error) {
      setMessage({ 
        type: 'error', 
        text: error.message || 'An error occurred. Please try again.' 
      });
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <AuthLayout>
      <section id="login-form" className="pt-4 pb-4 ">
        <br /><br /><br /><br />
        <div className="container-xl">
          <div className="row contact_22 mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="contact_2l row">
                <div className="col-md-6">
                  <h4 className='text-white text-center'>LOGIN</h4>
                </div>
              </div>
              
              {message.text && (
                <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
                  <p className="mb-0">{message.text}</p>
                </div>
              )}
              
              <form onSubmit={handleSubmit}>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                      minLength="8"
                    />
                  </div>
                </div>

                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <div className='text-white text-end'>
                      <Link to="/forgot-password" className="col_red">Forgot Password?</Link>
                    </div>
                  </div>
                </div>

                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <h6 className="mt-3 mb-0 text-center">
                      <button 
                        className="button" 
                        type="submit" 
                        disabled={loading}
                      >
                        {loading ? 'Logging in...' : 'Login'}
                      </button>
                    </h6>
                    <br />
                    <div className='text-white text-center'>
                      Don't have an account?{' '}
                      <Link to="/signup" className="col_red" style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br /><br /><br /><br />
      </section>
    </AuthLayout>
  );
};

export default LoginForm;
