// src/components/EventDetails.js
import React from 'react';
import ActionButtons from '../components/ActionButtons';
import API from '../config'; // Import the configuration

const EventDetails = ({ event, onCheckIn }) => {
  const formatEventDateTime = (dateString) => {
    const eventDate = new Date(dateString);
    return eventDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <section id="blog" className="pt-4 pb-4 bg_grey">
      <br /><br /><br /><br />
      <div className="container-xl">
        <div className="row blog_1">
          <div className="col-md-1"></div>
          <div className="col-md-10">
            <div className="blog_1l">
              <div className="blog_1l1">
                <div className="popular_2i1lm position-relative clearfix">
                  <div className="popular_2i1lm1 clearfix">
                    <div className="grid">
                      <figure className="effect-jazz mb-0">
                        <a href="#">
                          <img
                            src={
                              event.event_graphic
                                ? `${API.UPLOADS}/${event.event_graphic}`
                                : 'img/default-event.jpg'
                            }
                            className="w-100"
                            alt="Event Graphic"
                            style={{ 
                              height: '450px',
                              objectFit: 'cover',
                              objectPosition: 'center'
                            }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/path_to_default_image.jpg'; // Replace with your default image path
                            }}
                          />
                        </a>
                      </figure>
                    </div>
                  </div>
                  <div className="popular_2i1lm2 position-absolute top-0 w-100 text-center clearfix">
                    <ul>
                      <li className="d-inline-block">
                        <a href="#">
                          <i className="fa fa-link col_red"></i>
                        </a>
                      </li>
                      <li className="d-inline-block">
                        <a href="#">
                          <i className="fa fa-search col_red"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="blog_1l1 mt-3">
                  <div className="row">
                    <div className="col-md-8" style={{ marginTop: '15px' }}>
                      <h5 className='text-white'>
                        <i className="fa fa-folder-open col_red me-1"></i>
                        {event.event_category || 'Event Type'}
                      </h5>
                    </div>
                    <div className="col-md-4" id="action-buttons">
                      <ActionButtons event={event} onCheckIn={onCheckIn} />
                    </div>
                  </div>
                  <h2 className="mt-3">
                    <a className="col_red" href="#">
                      {event.event_title || 'Event Title'}
                    </a>
                  </h2>
                  <h6 className="fw-normal mt-3 col_light">
                    <span>
                      <i className="fa fa-clock-o me-1 align-middle col_red"></i>
                      {formatEventDateTime(event.event_date)}, {event.event_time} - {event.event_end_time || 'N/A'}
                    </span>
                    <span className="ms-3">
                      <i className="fa fa-user me-1 align-middle col_red"></i>
                      {event.business_name || 'Business Name'}
                    </span>
                    <span className="ms-3">
                      <i className="fa fa-map-marker me-1 align-middle col_red"></i>
                      {event.location || 'Location'}
                    </span>
                  </h6>
                  <p className="mt-3 event-summary">
                    {event.event_summary || 'This is where the event summary will go.'}
                  </p>
                </div>
                <div className="blog_1l2">
                  <p>
                    {event.event_description || 'This is where the full event description will go.'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </section>
  );
};

export default EventDetails;
