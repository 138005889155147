// src/components/modals/EditEventModal.js
import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/AuthContext';
import LoadingModal from './LoadingModal';
import * as bootstrap from 'bootstrap'; // Import Bootstrap JS
import axios from 'axios'; // Import Axios
import API from '../../config'; // Import the configuration

const EditEventModal = ({ event, show, handleClose }) => {
  // Access user from AuthContext
  const { user } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    event_title: event.event_title || '',
    event_date: event.event_date ? event.event_date.split('T')[0] : '',
    event_time: event.event_time || '',
    event_end_time: event.event_end_time || '', // new field
    location: event.location || '',
    event_summary: event.event_summary || '',
    event_description: event.event_description || '',
    event_category: event.event_category || 'Conference',
    status: event.status || 'active',
  });
  const [eventGraphic, setEventGraphic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [uploadProgress, setUploadProgress] = useState(0);

  const modalRef = useRef(null);
  const modalInstance = useRef(null);

  // Define the handleHidden function to handle modal closure
  const handleHidden = () => {
    handleClose(); // Notify the parent component to update its state
    resetState();  // Reset the form and related states within the modal
  };

  useEffect(() => {
    if (modalRef.current) {
      // Initialize Bootstrap Modal
      modalInstance.current = new bootstrap.Modal(modalRef.current, {
        backdrop: 'static', // Prevent closing by clicking outside
        keyboard: false,    // Prevent closing with Esc key
      });

      if (show) {
        modalInstance.current.show();
      }

      // Event listener for when the modal is hidden
      modalRef.current.addEventListener('hidden.bs.modal', handleHidden);

      // Cleanup event listener on unmount
      return () => {
        if (modalInstance.current) {
          modalInstance.current.dispose();
        }
        if (modalRef.current) {
          modalRef.current.removeEventListener('hidden.bs.modal', handleHidden);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]); // Re-run when `show` prop changes

  const resetState = () => {
    setFormData({
      event_title: event.event_title || '',
      event_date: event.event_date ? event.event_date.split('T')[0] : '',
      event_time: event.event_time || '',
      event_end_time: event.event_end_time || '', // new field
      location: event.location || '',
      event_summary: event.event_summary || '',
      event_description: event.event_description || '',
      event_category: event.event_category || 'Conference',
      status: event.status || 'active',
    });
    setEventGraphic(null);
    setUploadProgress(0);
    setMessage({ type: '', text: '' });
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle graphic file change
  const handleGraphicChange = (e) => {
    setEventGraphic(e.target.files[0]);
  };

  // Handle form submission
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });
    setUploadProgress(0);

    const data = new FormData();
    data.append('event_title', formData.event_title);
    data.append('event_date', formData.event_date);
    data.append('event_time', formData.event_time);
    data.append('event_end_time', formData.event_end_time); // new field
    data.append('location', formData.location);
    data.append('event_summary', formData.event_summary);
    data.append('event_description', formData.event_description);
    data.append('event_category', formData.event_category);
    data.append('status', formData.status);
    if (eventGraphic) {
      data.append('event_graphic', eventGraphic);
    }

    try {
      const response = await axios.put(
        `${API.EVENTS}/${event.id}/update`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user.token}`, // Include the token here
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      const result = response.data;

      if (result.success) {
        setMessage({ type: 'success', text: 'Event updated successfully!' });
        // Automatically close the modal after a short delay (e.g., 2 seconds)
        setTimeout(() => {
          handleClose();
          window.location.reload();
        }, 2000);
      } else {
        setMessage({ type: 'danger', text: 'Failed to update event: ' + result.message });
      }
    } catch (error) {
      console.error('Error updating event:', error);
      // Check if error response exists and extract message
      const errorMessage = error.response?.data?.message || 'An error occurred while updating the event.';
      setMessage({ type: 'danger', text: errorMessage });
    } finally {
      setLoading(false);
    }
  };

  // Prevent rendering if user is not an event promoter or not the event owner
  if (!user || (user.user_type !== 'event_promoter' && user.id !== event.user_id)) {
    return null;
  }

  return (
    <>
      {/* Loading Modal */}
      <LoadingModal
        show={loading}
        message="Updating event..."
        isSuccess={false}
        isError={false}
        onClose={() => {}} // No-op: Modal is controlled via the `loading` state
      />

      <div
        className="modal fade"
        id={`editEventModal-${event.id}`} // Unique ID if multiple modals are present
        tabIndex="-1"
        aria-labelledby={`editEventLabel-${event.id}`}
        aria-hidden="true"
        ref={modalRef}
        align="start"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content custom-modal bg-dark text-white">
            <div className="modal-header">
              <h5 className="modal-title" id={`editEventLabel-${event.id}`}>
                Edit Event
              </h5>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Messages */}
              {message.text && (
                <div
                  className={`alert ${
                    message.type === 'success' ? 'alert-success' : 'alert-danger'
                  } text-center`}
                >
                  {message.text}
                </div>
              )}

              <form onSubmit={handleUpdate}>
                {/* Event Title */}
                <div className="mb-3">
                  <label htmlFor="event_title" className="form-label text-white">
                    Event Title
                  </label>
                  <input
                    type="text"
                    className="form-control custom-input"
                    id="event_title"
                    name="event_title"
                    value={formData.event_title}
                    onChange={handleChange}
                    placeholder="Event Title"
                    required
                  />
                </div>

                {/* Event Date */}
                <div className="mb-3">
                  <label htmlFor="event_date" className="form-label text-white">
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control custom-input"
                    id="event_date"
                    name="event_date"
                    value={formData.event_date}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Event Time */}
                <div className="mb-3">
                  <label htmlFor="event_time" className="form-label text-white">
                    Time
                  </label>
                  <input
                    type="time"
                    className="form-control custom-input"
                    id="event_time"
                    name="event_time"
                    value={formData.event_time}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Event End Time */}
                <div className="mb-3">
                  <label htmlFor="event_end_time" className="form-label text-white">
                    End Time
                  </label>
                  <input
                    type="time"
                    className="form-control custom-input"
                    id="event_end_time"
                    name="event_end_time"
                    value={formData.event_end_time}
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* Location */}
                <div className="mb-3">
                  <label htmlFor="location" className="form-label text-white">
                    Location
                  </label>
                  <input
                    type="text"
                    className="form-control custom-input"
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    placeholder="Location"
                    required
                  />
                </div>

                {/* Event Summary */}
                <div className="mb-3">
                  <label htmlFor="event_summary" className="form-label text-white">
                    Event Summary
                  </label>
                  <textarea
                    className="form-control custom-textarea"
                    id="event_summary"
                    name="event_summary"
                    value={formData.event_summary}
                    onChange={handleChange}
                    placeholder="Event Summary"
                    rows="3"
                    required
                  ></textarea>
                </div>

                {/* Event Description */}
                <div className="mb-3">
                  <label htmlFor="event_description" className="form-label text-white">
                    Event Description
                  </label>
                  <textarea
                    className="form-control custom-textarea"
                    id="event_description"
                    name="event_description"
                    value={formData.event_description}
                    onChange={handleChange}
                    placeholder="Event Description"
                    rows="5"
                    required
                  ></textarea>
                </div>

                {/* Event Category */}
                <div className="mb-3">
                  <label htmlFor="event_category" className="form-label text-white">
                    Event Category
                  </label>
                  <select
                    className="form-select custom-select"
                    id="event_category"
                    name="event_category"
                    value={formData.event_category}
                    onChange={handleChange}
                    required
                  >
                    <option value="Conference">Conference</option>
                    <option value="Music">Music</option>
                    <option value="Art">Art</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Networking">Networking</option>
                  </select>
                </div>

                {/* Event Graphic */}
                <div className="mb-3">
                  <label htmlFor="event_graphic" className="form-label text-white">
                    Upload Event Graphic
                  </label>
                  <input
                    type="file"
                    className="form-control custom-file-input"
                    id="event_graphic"
                    name="event_graphic"
                    accept="image/*"
                    onChange={handleGraphicChange}
                  />
                  {event.event_graphic && !eventGraphic && (
                    <small className="form-text text-white">
                      Current Graphic:{' '}
                      <a
                        href={`${API.UPLOADS}/${event.event_graphic}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-danger" // Assuming 'col_red' corresponds to 'text-danger'
                      >
                        View
                      </a>
                    </small>
                  )}
                  {eventGraphic && (
                    <small className="form-text text-muted">
                      New Graphic Selected: {eventGraphic.name}
                    </small>
                  )}
                </div>

                {/* Event Status */}
                <div className="mb-3">
                  <label className="form-label text-white">Event Status</label>
                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="statusActive"
                        value="active"
                        checked={formData.status === 'active'}
                        onChange={handleChange}
                        required
                      />
                      <label className="form-check-label text-white" htmlFor="statusActive">
                        Activate
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="status"
                        id="statusInactive"
                        value="inactive"
                        checked={formData.status === 'inactive'}
                        onChange={handleChange}
                      />
                      <label className="form-check-label text-white" htmlFor="statusInactive">
                        Deactivate
                      </label>
                    </div>
                  </div>
                </div>

                {/* Submit Button */}
                <div className="d-grid gap-2">
                  <button
                    type="submit"
                    className="btn text-white bg_red rounded-2 border-0"
                  >
                    Update Event
                  </button>
                </div>
              </form>

              {/* Show upload progress if uploading */}
              {uploadProgress > 0 && (
                <div className="mt-3" align="center">
                  <div className="progress">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      style={{ width: `${uploadProgress}%` }}
                      aria-valuenow={uploadProgress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {uploadProgress}%
                    </div>
                  </div>
                  <p className="mt-2 text-white">{`Uploading: ${uploadProgress}%`}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Define PropTypes for type checking
EditEventModal.propTypes = {
  event: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditEventModal;
