// src/config.js

const BASE_URL = 'https://spono.ai'; // Use your production URL

const API = {
  BASE_URL,
  AUTH: `${BASE_URL}/api/auth`,
  EVENTS: `${BASE_URL}/api/events`,
  FOOTAGE: `${BASE_URL}/api/footage`,
  CHECKIN: `${BASE_URL}/api/checkin`,
  MOMENTS: `${BASE_URL}/api/moments`,
  UPLOADS: `${BASE_URL}/uploads`,
};

export default API;
