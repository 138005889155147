// src/components/ForgotPasswordForm.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { forgotPassword } from '../../services/AuthService';
import AuthLayout from '../AuthLayout'; 

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });

    try {
      const response = await forgotPassword(email);
      
      if (response.success) {
        setMessage({ 
          type: 'success', 
          text: 'Reset code sent! Check your email.' 
        });
        
        // Wait a moment before redirecting
        setTimeout(() => {
          navigate('/verify-code', { state: { email } });
        }, 2000);
      } else {
        setMessage({ 
          type: 'error', 
          text: response.message || 'Failed to send reset code. Please try again.' 
        });
      }
    } catch (error) {
      setMessage({ 
        type: 'error', 
        text: error.message || 'An error occurred. Please try again later.' 
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <section id="forgot-password-form" className="pt-4 pb-4 ">
        <br /><br /><br /><br />
        <div className="container-xl">
          <div className="row contact_22 mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="contact_2l row">
                <div className="col-md-6">
                  <h4 className='text-white text-center'>FORGOT PASSWORD</h4>
                </div>
              </div>
              <div className="contact_2l row">
                <div className="col-md-6">
                  <p className='col_light'>Enter your email address and we'll send you a code to reset your password.</p>
                </div>
              </div>
              
              {message.text && (
                <div className={`contact_2l alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
                  <div className="col-md-6">
                    <p className="mb-0">{message.text}</p>
                  </div>
                </div>
              )}
              
              <form onSubmit={handleSubmit}>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <h6 className="mt-3 mb-0 text-center">
                      <button 
                        className="button" 
                        type="submit" 
                        disabled={loading}
                      >
                        {loading ? 'Sending...' : 'Send Reset Code'}
                      </button>
                    </h6>
                    <br />
                    <div className='text-white text-center'>
                      Remember your password? <Link to="/login" className="col_red" style={{ fontSize: '20px', fontWeight: 'bold' }}>Login</Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br /><br /><br /><br />
      </section>
    </AuthLayout>
  );
};

export default ForgotPasswordForm;
