// src/components/Carousel.js
import React, { useContext } from 'react';
import { EventsContext } from '../context/EventsContext';
import { Link } from 'react-router-dom';
import API from '../config'; // Import the configuration

const Carousel = () => {
  const { events } = useContext(EventsContext);
  const formatEventDateTime = (dateString) => {
    const eventDate = new Date(dateString);
    return eventDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const limitedEvents = events.slice(0, 8); // Limit to 8 events for the carousel

  return (
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      {/* Carousel Indicators */}
      <div className="carousel-indicators">
        {limitedEvents.map((_, index) => (
          <button
            type="button"
            key={index}
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to={index}
            className={index === 0 ? 'active' : ''}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>

      {/* Carousel Items */}
      <div className="carousel-inner">
        {limitedEvents.map((event, index) => (
          <div key={event.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <img
              src={`${API.UPLOADS}/${event.event_graphic}`}
              className="d-block w-100"
              alt={event.event_title}
              style={{ height: '900px', objectFit: 'cover' }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/path_to_default_image.jpg'; // Replace with your default image path
              }}
            />
            <div className="carousel-caption d-md-block">
              <div className="row" style={{ marginTop: '350px' }}>
                <div className="col-md-4"></div>
                <div className="col-md-4 align-middle">
                  {/* Event Title */}
                  <h1 className="font_60" style={{ textAlign: 'left' }}>
                    {event.event_title}
                  </h1>

                  {/* Event Category */}
                  <h6 className="mt-3" style={{ textAlign: 'left' }}>
                    <a className="bg_red p-2 pe-4 ps-4 ms-3 text-white d-inline-block" href="#">
                      {event.event_category}
                    </a>
                  </h6>

                  {/* Event Details */}
                  <p className="mt-3">{event.event_summary}</p>
                  <p className="mb-2">
                    <span className="col_red me-1 fw-bold">Location:</span> {event.location}
                  </p>
                  <p className="mb-2">
                    <span className="col_red me-1 fw-bold">Date:</span> {formatEventDateTime(event.event_date)}
                  </p>
                  {event.business_name && (
                    <p className="mb-2">
                      <span className="col_red me-1 fw-bold">Hosted by:</span> {event.business_name}
                    </p>
                  )}
                  <p className="mb-2">
                    <span className="col_red me-1 fw-bold">Start:</span> {event.event_time}
                    <span className="ms-3 col_red me-1 fw-bold">End:</span> {event.event_end_time || 'N/A'}
                  </p>

                  {/* More Info Link */}
                  <h6 className="mt-4 mb-0">
                    <Link className="button" to={`/event/${event.id}`}>
                      <i className="fa fa-info-circle me-1"></i> More Info
                    </Link>
                  </h6>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Carousel Controls */}
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
