// src/components/AuthLayout.js
import React from 'react';
import '../assets/css/Auth.css';

/**
 * Layout component for authentication-related pages
 * This provides a consistent layout structure for login, signup,
 * and password reset pages.
 */
const AuthLayout = ({ children }) => {
  return (
    <div className="auth-layout">
      {children}
    </div>
  );
};

export default AuthLayout;
