import React, { useEffect, useRef, useState } from 'react';
import * as bootstrap from 'bootstrap';
import API from '../../config';

export default function ViewPhotosModal({ show, eventPhotos = [], handleClose }) {
  const modalRef = useRef(null);
  const modalInstance = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  // Initialize the modal once
  useEffect(() => {
    if (modalRef.current) {
      modalInstance.current = new bootstrap.Modal(modalRef.current, {
        backdrop: 'static',
        keyboard: false,
      });

      const handleHidden = () => handleClose();
      modalRef.current.addEventListener('hidden.bs.modal', handleHidden);

      return () => {
        if (modalInstance.current) {
          modalInstance.current.dispose();
        }
        modalRef.current.removeEventListener('hidden.bs.modal', handleHidden);
      };
    }
  }, [handleClose]);

  // Show or hide the modal
  useEffect(() => {
    if (!modalInstance.current) return;
    
    if (show) {
      modalInstance.current.show();
      // Set the first image as selected by default when modal opens
      if (eventPhotos.length > 0 && !selectedImage) {
        setSelectedImage(eventPhotos[0]);
      }
    } else {
      modalInstance.current.hide();
      setSelectedImage(null);
    }
  }, [show, eventPhotos, selectedImage]);

  const handleImageClick = (photo) => {
    setSelectedImage(photo);
  };

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      aria-hidden="true"
      ref={modalRef}
    >
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content custom-modal bg-dark text-white">
          <div className="modal-header">
            <h5 className="modal-title">Event Photos</h5>
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={() => {
                if (modalInstance.current) {
                  modalInstance.current.hide();
                }
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {eventPhotos.length > 0 ? (
              <div className="row">
                {/* Main image display */}
                <div className="col-md-9 mb-3">
                  <div 
                    className="main-image-container" 
                    style={{ 
                      height: '500px', 
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center',
                      overflow: 'hidden',
                      background: '#1a1a1a',
                      borderRadius: '8px'
                    }}
                  >
                    {selectedImage && (
                      <img
                        src={selectedImage.url.startsWith('http') 
                          ? selectedImage.url 
                          : `${API.BASE_URL}${selectedImage.url}`
                        }
                        alt="Selected event photo"
                        style={{ 
                          maxWidth: '100%', 
                          maxHeight: '100%', 
                          objectFit: 'contain' 
                        }}
                      />
                    )}
                  </div>
                </div>

                {/* Thumbnails sidebar */}
                <div className="col-md-3">
                  <div 
                    className="thumbnails-container"
                    style={{ 
                      maxHeight: '500px', 
                      overflowY: 'auto',
                      paddingRight: '10px'
                    }}
                  >
                    {eventPhotos.map((photo, index) => (
                      <div 
                        key={index}
                        className={`thumbnail-wrapper mb-3 ${selectedImage === photo ? 'border border-danger' : ''}`}
                        style={{ 
                          cursor: 'pointer',
                          borderRadius: '4px',
                          overflow: 'hidden',
                          height: '100px'
                        }}
                        onClick={() => handleImageClick(photo)}
                      >
                        <img
                          src={photo.url.startsWith('http') 
                            ? photo.url 
                            : `${API.BASE_URL}${photo.url}`
                          }
                          alt={`Thumbnail ${index + 1}`}
                          style={{ 
                            width: '100%', 
                            height: '100%', 
                            objectFit: 'cover'
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-center">No photos available for this event.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}