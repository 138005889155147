// src/components/UpcomingEvents.js
import React, { useContext } from 'react';
import { EventsContext } from '../context/EventsContext';
import { Link } from 'react-router-dom';
import API from '../config'; // Import the configuration

const UpcomingEvents = () => {
  const { events } = useContext(EventsContext);

  // Filter events that are in the future
  const today = new Date();
  const upcomingEvents = events
    .filter((event) => new Date(event.event_date) >= today)
    .sort((a, b) => new Date(a.event_date) - new Date(b.event_date));

  // Limit to the next 8 upcoming events
  const limitedEvents = upcomingEvents.slice(0, 8);

  // Group events into chunks of 3 for the carousel slides
  const slides = [];
  for (let i = 0; i < limitedEvents.length; i += 3) {
    slides.push(limitedEvents.slice(i, i + 3));
  }

  return (
    <section id="upcome" className="pt-4 pb-5">
      <div className="container">
        <div className="row trend_1">
          <div className="col-md-6 col-6">
            <div className="trend_1l">
              <h4 className="mb-0 text-white">
                <i className="fa fa-calendar align-middle col_red me-1"></i>
                Upcoming <span className="col_red">Events</span>
              </h4>
            </div>
          </div>
          <div className="col-md-6 col-6">
            <div className="trend_1r text-end">
              <h6 className="mb-0">
                <Link className="button" to="/events">
                  View All
                </Link>
              </h6>
            </div>
          </div>
        </div>
        <div className="row trend_2 mt-4">
          <div id="carouselExampleCaptions2" className="carousel slide" data-bs-ride="carousel">
            {/* Carousel Indicators */}
            <div className="carousel-indicators">
              {slides.map((_, index) => (
                <button
                  key={index}
                  type="button"
                  data-bs-target="#carouselExampleCaptions2"
                  data-bs-slide-to={index}
                  className={index === 0 ? 'active' : ''}
                  aria-label={`Slide ${index + 1}`}
                ></button>
              ))}
            </div>
            {/* Carousel Items */}
            <div className="carousel-inner">
              {slides.map((slideEvents, slideIndex) => (
                <div
                  key={slideIndex}
                  className={`carousel-item ${slideIndex === 0 ? 'active' : ''}`}
                >
                  <div className="trend_2i row g-3"> {/* Added 'g-3' for gutters */}
                    {slideEvents.map((event) => (
                      <div key={event.id} className="col-md-4 col-6">
                        {/* Event Card */}
                        <div className="trend_2im clearfix position-relative">
                          <div className="trend_2im1 clearfix">
                            <div className="grid">
                              <figure className="effect-jazz mb-0">
                                <Link to={`/event/${event.id}`}>
                                  <img
                                    src={`${API.UPLOADS}/${event.event_graphic}`}
                                    className="w-100"
                                    alt={event.event_title}
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = '/path_to_default_image.jpg'; // Replace with your default image path
                                    }}
                                  />
                                </Link>
                              </figure>
                            </div>
                          </div>
                          <div className="trend_2im2 clearfix text-center position-absolute w-100 top-0">
                            <span className="fs-1">
                              <Link className="col_red" to={`/event/${event.id}`}>
                                {/* Optional: Add overlay content */}
                              </Link>
                            </span>
                          </div>
                        </div>
                        <div className="trend_2ilast bg_grey p-3 clearfix">
                          <h5>
                            <Link className="col_red" to={`/event/${event.id}`}>
                              {event.event_title}
                            </Link>
                          </h5>
                          <p className="mb-2">{event.event_summary}</p>
                          {/* Replace "1 Views" with actual view count if available */}
                          <p className="mb-0">1 Views</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* Carousel Controls */}
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleCaptions2"
              data-bs-slide="prev"
            >
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleCaptions2"
              data-bs-slide="next"
            >
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpcomingEvents;
