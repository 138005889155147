// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Use Link for internal navigation

const Footer = () => {
  return (
    <>
      {/* Main Footer Section */}
      <section id="footer">
        <div className="footer_m clearfix">
          <div className="container">
            <div className="row footer_1">
              {/* Left Column */}
              <div className="col-md-4 col-sm-12 mb-4">
                <div className="footer_1i">
                  <h3>
                    <Link className="text-white" to="/">
                      S P O N O
                    </Link>
                  </h3>
                  <p className="mt-3">
                    {/* Optional: Add a brief description about SPONO */}
                    SPONO is your go-to platform for event management and more. Stay connected with us!
                  </p>
                  <h6 className="fw-normal mt-3 text-white">
                    <i className="fa fa-envelope fs-5 align-middle col_red me-1"></i> team@spono.ai
                  </h6>
                </div>
              </div>

              {/* Middle Column (Hidden on Mobile) */}
              <div className="col-md-4 d-none d-md-block"></div>

              {/* Right Column */}
              <div className="col-md-4 col-sm-12 mb-4">
                <div className="footer_1i">
                  <h4 className='text-white'>
                    Sign <span className="col_red">Newsletter</span>
                  </h4>
                  <p className="mt-3">
                    Subscribe to our newsletter to receive the latest news and updates from SPONO.
                  </p>
                  <form>
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control bg-black sign-email"
                        placeholder="Email"
                        style={{ height: 44 }}
                        required
                      />
                      <button
                        className="btn btn text-white bg_red rounded-0 border-0"
                        type="submit"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                  <ul className="social-network social-circle mb-0 mt-4">
                    <li>
                      <a href="#" className="icoInstagram" title="Instagram">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="icoFacebook" title="Facebook">
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="icoTwitter" title="Twitter">
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="icoYouTube" title="YouTube">
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="icoLinkedin" title="LinkedIn">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Bottom Footer Section */}
      <section id="footer_b" className="pt-3 pb-3 bg_grey">
        <div className="container">
          <div className="row footer_1">
            <div className="col-md-4 col-sm-12">
            </div>
            <div className="col-md-4 col-sm-12 mb-md-0" align="center">
              <div className="footer_1l">
                <p className="mb-0">© {new Date().getFullYear()} SPONO. All Rights Reserved</p>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
