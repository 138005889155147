// src/components/modals/LoadingModal.js
import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LoadingModal = ({ show = false, message = 'Loading...', isSuccess = false, isError = false, onClose = () => {} }) => {
  return (
    <Modal show={show} centered backdrop="static" keyboard={false} onHide={onClose}>
      <Modal.Body className="text-center p-4">
        {!isSuccess && !isError && (
          <Spinner animation="border" role="status" variant="primary" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {isSuccess && <div className="text-success mb-3 fs-1">✓</div>}
        {isError && <div className="text-danger mb-3 fs-1">✗</div>}
        <p className="mb-0">{message}</p>
      </Modal.Body>
    </Modal>
  );
};

LoadingModal.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
  isSuccess: PropTypes.bool,
  isError: PropTypes.bool,
  onClose: PropTypes.func
};

export default LoadingModal;
