import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { resetPassword } from '../../services/AuthService';

const ResetPasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  
  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if token and email were provided in state
  const token = location.state?.token || '';
  const email = location.state?.email || '';
  
  if (!token || !email) {
    // Redirect to forgot password if token or email is missing
    navigate('/forgot-password');
    return null;
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });
    
    // Validate passwords match
    if (password !== confirmPassword) {
      setMessage({ type: 'error', text: 'Passwords do not match!' });
      setLoading(false);
      return;
    }
    
    try {
      const response = await resetPassword(password, token);
      
      if (response.success) {
        setMessage({ 
          type: 'success', 
          text: 'Password reset successful! Redirecting to login...' 
        });
        
        // Wait a moment before redirecting
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        setMessage({ 
          type: 'error', 
          text: response.message || 'Failed to reset password. Please try again.' 
        });
      }
    } catch (error) {
      setMessage({ 
        type: 'error', 
        text: error.message || 'An error occurred. Please try again later.' 
      });
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <section id="reset-password-form" className="pt-4 pb-4 ">
      <br /><br /><br /><br />
      <div className="container-xl">
        <div className="row contact_22 mt-4">
          <div className="col-md-4"></div>
          <div className="col-md-8">
            <div className="contact_2l row">
              <div className="col-md-6">
                <h4 className='text-white text-center'>RESET PASSWORD</h4>
              </div>
            </div>
            <div className="contact_2l row">
              <div className="col-md-6">
                <p className='col_light'>Choose a new password for your account.</p>
              </div>
            </div>
            
            {message.text && (
              <div className={`contact_2l alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
                <div className="col-md-6">
                  <p className="mb-0">{message.text}</p>
                </div>
              </div>
            )}
            
            <form onSubmit={handleSubmit}>
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <input
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    minLength="8"
                  />
                </div>
              </div>
              
              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <input
                    type="password"
                    name="confirmPassword"
                    className="form-control"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    minLength="8"
                  />
                </div>
              </div>

              <div className="contact_2l1 mt-3 row">
                <div className="col-md-6">
                  <h6 className="mt-3 mb-0 text-center">
                    <button 
                      className="button" 
                      type="submit" 
                      disabled={loading}
                    >
                      {loading ? 'Resetting...' : 'Reset Password'}
                    </button>
                  </h6>
                  <br />
                  <div className='text-white text-center'>
                    Remember your password? <Link to="/login" className="col_red" style={{ fontSize: '20px', fontWeight: 'bold' }}>Login</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <br /><br /><br /><br />
    </section>
  );
};

export default ResetPasswordForm;
