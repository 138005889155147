// src/components/VerifyCodeForm.js
import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { verifyResetCode } from '../../services/AuthService';
import AuthLayout from '../AuthLayout';

const VerifyCodeForm = () => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email || '';
  
  if (!email) {
    // Redirect back to forgot password if email is not provided
    navigate('/forgot-password');
    return null;
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage({ type: '', text: '' });
    
    try {
      const result = await verifyResetCode(email, code);
      
      if (result.success && result.token) {
        setMessage({ type: 'success', text: 'Code verified successfully!' });
        
        navigate('/reset-password', { 
          state: { 
            email, 
            token: result.token 
          } 
        });
      } else {
        setMessage({ type: 'error', text: 'Invalid code. Please try again.' });
      }
    } catch (error) {
      setMessage({ type: 'error', text: error.message || 'Failed to verify code' });
    } finally {
      setLoading(false);
    }
  };
  
  const handleResendCode = async () => {
    navigate('/forgot-password', { state: { email } });
  };
  
  return (
    <AuthLayout>
      <section id="verify-code-form" className="pt-4 pb-4 ">
        <br /><br /><br /><br />
        <div className="container-xl">
          <div className="row contact_22 mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-8">
              <div className="contact_2l row">
                <div className="col-md-6">
                  <h4 className='text-white text-center'>VERIFY CODE</h4>
                </div>
              </div>
              <div className="contact_2l row">
                <div className="col-md-6">
                  <p className='col_light'>We've sent a verification code to <strong className='col_red'>{email}</strong>. Please enter it below.</p>
                </div>
              </div>
              
              {message.text && (
                <div className={`contact_2l alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
                  <div className="col-md-6">
                    <p className="mb-0">{message.text}</p>
                  </div>
                </div>
              )}
              
              <form onSubmit={handleSubmit}>
                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter 6-digit code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      required
                      maxLength="6"
                    />
                  </div>
                </div>

                <div className="contact_2l1 mt-3 row">
                  <div className="col-md-6">
                    <h6 className="mt-3 mb-0 text-center">
                      <button 
                        className="button" 
                        type="submit" 
                        disabled={loading || code.length !== 6}
                      >
                        {loading ? 'Verifying...' : 'Verify Code'}
                      </button>
                    </h6>
                    <br />
                    <div className='text-white text-center'>
                      <button 
                        type="button" 
                        onClick={handleResendCode} 
                        className="col_red" 
                        style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                        disabled={loading}
                      >
                        Resend Code
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <br /><br /><br /><br />
      </section>
    </AuthLayout>
  );
};

export default VerifyCodeForm;
